import React from 'react';

const Main = () => {
   return (
      <div className="main">
         <h3>Welcome</h3>
         <p>
            <span> in the best car insurance company <strong>ever</strong>. </span>
            <h1><span role="img" aria-label="car">🚗</span></h1>
         </p>
      </div>
   );
}

export default Main;
