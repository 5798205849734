

export const FETCH_POLICIES_INIT = 'FETCH_POLICIES_INIT';
export const FETCH_POLICIES_SUCCESS = 'FETCH_POLICIES_SUCCESS';
export const FETCH_POLICIES_FAILURE = 'FETCH_POLICIES_FAILURE';

export const CREATE_POLICY_INIT = 'CREATE_POLICY_INIT';
export const CREATE_POLICY_SUCCESS = 'CREATE_POLICY_SUCCESS';
export const CREATE_POLICY_FAILURE = 'CREATE_POLICY_FAILURE';

export const PREMIUM_CALCULATION_INIT = 'PREMIUM_CALCULATION_INIT';
export const PREMIUM_CALCULATION_SUCCESS = 'PREMIUM_CALCULATION_SUCCESS';
export const PREMIUM_CALCULATION_FAILURE = 'PREMIUM_CALCULATION_FAILURE';

export const FETCH_BRANDS_INIT = 'FETCH_BRANDS_INIT';
export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS';
export const FETCH_BRANDS_FAILURE = 'FETCH_BRANDS_FAILURE';

export const FETCH_MODELS_INIT = 'FETCH_MODELS_INIT';
export const FETCH_MODELS_SUCCESS = 'FETCH_MODELS_SUCCESS';
export const FETCH_MODELS_FAILURE = 'FETCH_MODELS_FAILURE';

